import React from 'react';

export const Logo = ({ height = 24, width = 150, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 150 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M24.9538 6.78788C24.2884 5.87879 23.3809 5.21212 22.292 4.72727C21.2031 4.24242 19.9931 4 18.7227 4C17.3918 4 16.1214 4.24242 14.972 4.72727C13.8226 5.21212 12.7336 5.87879 11.8262 6.72727C10.9188 7.57576 10.1323 8.66667 9.52736 9.87879C8.9224 11.0909 8.49893 12.4242 8.31744 13.9394C8.13595 15.3939 8.19645 16.7879 8.43843 18C8.74091 19.2121 9.22488 20.303 9.89033 21.1515C10.5558 22 11.4632 22.7273 12.4917 23.2121C13.5201 23.697 14.73 23.9394 16.1214 23.9394C17.4523 23.9394 18.7227 23.697 19.9326 23.2121C21.0821 22.7273 22.171 22.0606 23.0784 21.1515C23.9859 20.303 24.7118 19.2121 25.3168 18C25.9217 16.7879 26.2847 15.3939 26.4662 13.9394C26.6477 12.4848 26.5872 11.0909 26.3452 9.87879C26.1637 8.72727 25.6193 7.69697 24.9538 6.78788ZM13.2781 14C13.5201 12.2424 14.0645 10.8485 14.972 9.81818C15.8189 8.78788 16.9079 8.30303 18.2388 8.30303C19.5697 8.30303 20.4771 8.78788 21.0821 9.75758C21.687 10.7879 21.8685 12.1818 21.6265 13.8788C21.3845 15.6364 20.8401 17.0909 19.9326 18.1212C19.0857 19.1515 17.9968 19.6364 16.6659 19.6364C15.335 19.6364 14.4275 19.1515 13.8831 18.1818C13.2781 17.2121 13.0966 15.8182 13.2781 14Z"
      fill="#F04F4B"
    />
    <path
      d="M46.0669 23.5152H50.9066L53.3869 4.48486H48.5472L46.0669 23.5152Z"
      fill="#F04F4B"
    />
    <path
      d="M65.365 4.06055C63.9131 4.06055 62.5822 4.30297 61.3723 4.84843C60.1624 5.33327 59.0734 6.06055 58.166 6.96964C57.2586 7.87873 56.4721 8.90903 55.8672 10.1212C55.2622 11.3333 54.8992 12.6666 54.7177 13.9999C54.5363 15.3939 54.5363 16.7272 54.7782 17.9393C55.0202 19.1515 55.4437 20.2424 56.1092 21.1515C56.7746 22.0605 57.682 22.7272 58.8315 23.2727C59.9809 23.7575 61.3723 23.9999 62.9452 23.9999C63.9736 23.9999 65.002 23.8787 65.97 23.6969C66.9984 23.5151 67.7243 23.2727 68.2688 23.0302L68.4503 22.9696L68.3293 18.7878L67.9058 18.9696C67.3614 19.1515 66.7564 19.3333 66.091 19.4545C65.365 19.5757 64.6995 19.6363 63.9736 19.6363C62.1587 19.6363 60.8883 19.1515 60.2834 18.1818C59.6179 17.2121 59.4364 15.8181 59.6784 14.0605C59.9204 12.2424 60.5253 10.7878 61.4933 9.81812C62.4612 8.84843 63.7316 8.36358 65.244 8.36358C65.9095 8.36358 66.5749 8.42418 67.1194 8.5454C67.7243 8.66661 68.2083 8.84843 68.6318 9.03024L68.9343 9.15146L70.5071 5.09085L70.2652 4.96964C68.8133 4.36358 67.1799 4.06055 65.365 4.06055Z"
      fill="#F04F4B"
    />
    <path
      d="M99.4243 23.5152H104.264L106.744 4.48486H101.905L99.4243 23.5152Z"
      fill="#F04F4B"
    />
    <path
      d="M124.288 6.60594C123.743 5.81806 122.957 5.212 121.989 4.78776C121.021 4.36352 119.751 4.12109 118.178 4.12109C116.605 4.12109 115.093 4.24231 113.762 4.42412C112.431 4.60594 111.342 4.84837 110.434 5.09079L110.253 5.1514L107.894 23.4544H112.733L114.669 8.60594C114.972 8.54534 115.274 8.54534 115.758 8.48473C116.363 8.42412 116.907 8.36352 117.391 8.36352C118.057 8.36352 118.662 8.48473 119.085 8.66655C119.509 8.84837 119.811 9.09079 119.993 9.45443C120.235 9.81806 120.356 10.3635 120.356 11.0302C120.416 11.6969 120.356 12.5453 120.235 13.515L118.904 23.515H123.743L125.135 12.909C125.316 11.6362 125.316 10.4847 125.195 9.39382C125.135 8.30291 124.832 7.33321 124.288 6.60594Z"
      fill="#F04F4B"
    />
    <path
      d="M141.469 0L140.803 4.9697C140.44 4.78788 139.956 4.60606 139.472 4.42424C138.807 4.18182 137.96 4.12121 137.053 4.12121C135.722 4.12121 134.451 4.36364 133.362 4.84848C132.273 5.33333 131.306 6 130.459 6.84848C129.612 7.69697 128.946 8.78788 128.402 10C127.857 11.2121 127.494 12.5455 127.313 14.0606C127.131 15.5758 127.192 16.9697 127.434 18.1818C127.736 19.3939 128.22 20.4848 128.946 21.3333C129.672 22.1818 130.64 22.8485 131.729 23.3333C132.878 23.8182 134.149 24 135.661 24C137.053 24 138.444 23.8788 139.775 23.697C141.106 23.4545 142.195 23.2727 143.102 22.9697L143.284 22.9091L146.369 0H141.469ZM138.021 19.5758C137.537 19.6364 136.992 19.6364 136.327 19.6364C134.875 19.6364 133.786 19.1515 133.12 18.1818C132.394 17.2121 132.152 15.7576 132.394 14C132.636 12.2424 133.12 10.8485 133.967 9.87879C134.754 8.90909 135.843 8.42424 137.355 8.42424C138.021 8.42424 138.626 8.54545 139.17 8.72727C139.654 8.90909 140.077 9.09091 140.38 9.33333L138.989 19.3939C138.686 19.4545 138.384 19.5152 138.021 19.5758Z"
      fill="#F04F4B"
    />
    <path
      d="M35.722 9.81818C36.6295 8.84849 37.8999 8.36364 39.4728 8.36364C40.1382 8.36364 40.8037 8.42424 41.3482 8.54546C41.9531 8.66667 42.4371 8.84849 42.8606 9.0303L43.163 9.15152L44.7359 5.09091L44.4939 4.9697C42.9815 4.30303 41.3482 4 39.5938 4C38.1419 4 36.811 4.24242 35.601 4.78788C34.3911 5.27273 33.3022 6 32.3948 6.90909C31.4873 7.81818 30.7009 8.84849 30.0959 10.0606C29.491 11.2727 29.128 12.6061 28.9465 13.9394C28.765 15.3333 28.765 16.6667 29.007 17.8788C29.249 19.0909 29.6725 20.1818 30.3379 21.0909C31.0034 22 31.9108 22.6667 33.0602 23.2121C34.2096 23.697 35.601 23.9394 37.1739 23.9394C38.2024 23.9394 39.2308 23.8182 40.1987 23.6364C41.2272 23.4546 41.9531 23.2121 42.4976 22.9697L42.6186 22.9091L43.8285 13.6364H38.9888L38.2024 19.6364H38.1419C36.327 19.6364 35.0566 19.1515 34.4516 18.1818C33.7862 17.2121 33.6047 15.8182 33.8467 14.0606C34.1491 12.2424 34.7541 10.7879 35.722 9.81818Z"
      fill="#F04F4B"
    />
    <path
      d="M89.5635 16.0606L87.7486 4.48486H83.9978L78.9767 16.0606L77.3433 4.48486H72.2012L76.0124 23.5152H79.9446L84.9053 11.6364L86.7202 23.5152H90.5919L99.4243 4.48486H94.3426L89.5635 16.0606Z"
      fill="#F04F4B"
    />
    <path
      d="M2.51149 0H7.35116L4.26587 24C1.66455 24 -0.271319 21.7576 0.03116 19.1515L2.51149 0Z"
      fill="#F04F4B"
    />
  </svg>
);

export const LogoIcon = ({ height = 32, width = 27 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.0624836 17.6762L2.22557 0.5H10.6234L7.37879 26.5C2.92539 26.5 -0.510097 22.3238 0.0624836 17.6762Z"
      fill="#f04f4b"
    />
    <path
      d="M9.66938 26.4999L12.3414 5.0129H20.7393L18.0672 26.4999H9.66938Z"
      fill="#f04f4b"
    />
    <path
      d="M20.3575 26.5L23.6022 0.5H32L28.7554 26.5H20.3575Z"
      fill="#f04f4b"
    />
  </svg>
);

export const LogoText = ({ height = 22, width = 120 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 119 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.729405 9.35192C0.860213 8.76329 0.99102 8.17466 1.12183 7.58603C2.3645 3.00778 6.15791 0.457033 10.9978 0.849454C11.5864 0.914858 12.175 0.980263 12.7637 1.11107C13.8755 1.30728 14.922 1.89591 16.0993 1.43809C16.0993 3.00777 16.0993 4.38125 16.0993 6.01634C15.8376 5.88553 15.576 5.82013 15.3798 5.62392C13.6139 4.11964 11.5864 3.59641 9.29728 3.98883C7.59678 4.31585 6.28871 5.23149 5.50387 6.80118C4.1958 9.35192 4.1958 11.9681 5.50387 14.5188C7.53138 18.443 13.1561 18.3776 15.7068 15.7615C15.903 15.5653 16.0338 15.1728 16.4917 15.4999C16.2955 16.8079 16.1647 18.116 15.903 19.4895C15.903 19.6203 15.7722 19.8165 15.6414 19.8165C11.7826 21.0592 7.98921 21.19 4.45741 18.8354C2.29909 17.3966 1.18723 15.2382 0.794809 12.6875C0.794809 12.5567 0.729405 12.4259 0.664001 12.2951C0.729405 11.2486 0.729405 10.2676 0.729405 9.35192Z"
      fill="black"
    />
    <path
      d="M59.3964 21.19C58.677 19.6203 58.0229 18.0507 57.3035 16.481C55.7992 13.1454 54.2295 9.8098 52.7252 6.47422C52.2674 5.49317 51.8096 4.44671 50.6977 3.98889C50.5669 3.92348 50.5015 3.53106 50.4361 3.26945C52.6598 3.26945 54.7528 3.26945 56.9111 3.26945C56.9111 3.53106 56.9111 3.72727 56.9111 3.92348C56.1262 4.2505 55.9954 4.44671 56.257 5.16615C57.4343 8.10931 58.677 10.9871 59.9196 13.8648C59.9196 13.8648 59.9196 13.8648 60.0505 13.9302C60.5083 12.8838 60.9661 11.9027 61.3585 10.9217C62.1434 9.09037 62.9282 7.25907 63.7131 5.42776C63.9747 4.83913 64.2363 4.2505 63.386 3.92348C63.2552 3.85808 63.3206 3.46565 63.2552 3.20404C65.2173 3.20404 67.0486 3.20404 68.9453 3.20404C69.0762 3.59646 68.9453 3.85808 68.6183 4.11969C68.0297 4.64292 67.4411 5.23156 67.0486 5.951C65.9368 8.17472 64.8903 10.5292 63.8439 12.8184C62.6666 15.3691 61.4893 17.9853 60.3121 20.536C60.2467 20.7322 60.1813 20.9284 60.1159 21.1246C59.9196 21.19 59.658 21.19 59.3964 21.19Z"
      fill="black"
    />
    <path
      d="M83.1379 12.7529C78.9521 12.7529 74.8317 12.7529 70.7113 12.7529C70.7767 15.4999 72.0847 17.2004 74.5047 17.5928C76.8592 17.9852 79.0829 17.462 80.9796 15.9577C81.372 15.6961 81.6337 15.5653 82.0915 15.8269C81.9607 17.0042 81.8299 18.2468 81.6337 19.4241C81.6337 19.5549 81.5029 19.6857 81.3721 19.7511C78.4289 20.9284 75.4203 21.2554 72.4118 20.4705C70.188 19.8819 68.6837 18.3122 67.7681 16.2847C66.5254 13.3416 66.5254 10.2676 67.9643 7.38983C69.1416 4.90449 71.2345 3.66182 73.9814 3.2694C75.4857 3.07319 76.9246 3.13859 78.3635 3.66182C81.4374 4.77368 83.0071 7.38983 83.2034 10.3984C83.2034 11.1178 83.1379 11.8373 83.1379 12.7529ZM79.4753 9.87517C79.2137 7.38983 77.5132 5.95095 74.9625 6.01635C72.8042 6.08176 70.9075 7.84765 70.7767 9.87517C73.6544 9.87517 76.5322 9.87517 79.4753 9.87517Z"
      fill="black"
    />
    <path
      d="M116.428 12.7529C112.177 12.7529 108.057 12.7529 103.871 12.7529C103.936 15.3037 105.114 17.0696 107.206 17.5274C109.757 18.116 112.112 17.6582 114.139 16.0231C114.466 15.7615 114.793 15.6307 115.251 15.8923C115.12 17.0696 114.99 18.3122 114.859 19.4895C114.859 19.6203 114.662 19.7511 114.532 19.7511C111.523 20.9938 108.449 21.3208 105.31 20.4052C103.282 19.8165 101.843 18.3776 100.993 16.4809C99.6197 13.4724 99.6196 10.3984 101.059 7.38983C102.236 4.9045 104.329 3.59642 107.076 3.204C109.038 2.94239 110.869 3.204 112.57 4.11965C114.662 5.29692 115.774 7.19362 116.167 9.48275C116.428 10.5292 116.428 11.5757 116.428 12.7529ZM112.7 9.87517C112.373 7.52064 110.869 5.88555 108.188 6.01636C106.029 6.14716 104.067 7.91306 104.002 9.87517C106.879 9.87517 109.757 9.87517 112.7 9.87517Z"
      fill="black"
    />
    <path
      d="M17.0803 11.9027C17.1457 9.41735 17.7997 7.12823 19.631 5.36233C20.8737 4.18506 22.378 3.53103 24.0131 3.26941C26.106 2.87699 28.1989 3.0732 30.161 3.92345C32.5809 5.03531 34.0198 6.86661 34.6084 9.48276C35.1971 12.0989 35.0009 14.5842 33.6274 16.9388C32.3193 19.1625 30.2264 20.3398 27.7411 20.7322C25.5173 21.0592 23.2936 20.863 21.3315 19.7511C18.977 18.443 17.7343 16.3501 17.2765 13.7994C17.1457 13.1454 17.1457 12.4913 17.0803 11.9027ZM20.7429 12.0989C20.6775 15.1075 22.378 17.5274 25.7136 17.7236C28.0027 17.789 30.0956 16.9388 30.9458 14.2572C31.2729 13.2108 31.3383 12.0989 31.2729 10.987C31.0766 9.02493 30.2264 7.38984 28.3297 6.6704C26.3022 5.88556 24.2747 6.01636 22.5742 7.45524C21.1353 8.5671 20.7429 10.1368 20.7429 12.0989Z"
      fill="black"
    />
    <path
      d="M45.8579 20.2089C45.8579 19.9473 45.8579 19.7511 45.8579 19.4895C47.2314 19.3587 47.3622 18.3776 47.4276 17.3312C47.493 14.7804 47.493 12.2297 47.5584 9.74436C47.5584 9.28654 47.493 8.89412 47.3622 8.43629C46.9698 6.93201 46.1849 6.34338 44.6806 6.27798C41.7375 6.08176 40.2332 8.17468 40.1678 10.333C40.1024 12.8183 40.2332 15.3037 40.2986 17.789C40.2986 18.5739 40.6256 19.2279 41.4759 19.4241C41.6721 19.4895 41.7375 19.8819 41.9337 20.2089C39.3829 20.2089 37.0938 20.2089 34.7393 20.2089C34.7393 20.0127 34.7393 19.7511 34.7393 19.4895C34.8701 19.4241 35.0009 19.3587 35.1971 19.3587C35.7203 19.2279 36.1128 18.9009 36.1782 18.3776C36.309 17.2658 36.4398 16.1539 36.4398 15.0421C36.4398 12.3605 36.4398 9.67896 36.3744 6.99741C36.3744 6.47418 36.2436 5.95095 36.1128 5.49313C35.9819 4.9699 35.7203 4.64289 35.1971 4.57748C34.6739 4.51208 34.6085 4.18506 34.7393 3.66183C35.1971 3.66183 35.6549 3.66183 36.1782 3.66183C37.0938 3.66183 38.0095 3.66183 38.9905 3.66183C39.3829 3.66183 39.5791 3.72723 39.5791 4.18506C39.5791 4.57748 39.71 4.9699 39.8408 5.49313C40.8218 4.31586 41.9991 3.59643 43.438 3.33481C45.0731 3.00779 46.7081 3.0732 48.2778 3.79264C50.1091 4.64288 51.0248 6.08176 51.0902 8.10927C51.1556 10.7908 51.1556 13.5378 51.221 16.2193C51.221 16.7425 51.2864 17.3312 51.3518 17.8544C51.4826 18.7701 51.875 19.4241 52.9215 19.5549C52.9215 19.8165 52.9215 20.0127 52.9215 20.2743C50.5016 20.2089 48.2124 20.2089 45.8579 20.2089Z"
      fill="black"
    />
    <path
      d="M89.8745 20.2743C87.3238 20.2743 85.0347 20.2743 82.6801 20.2743C82.6801 20.0127 82.6801 19.8165 82.6801 19.5549C84.3152 19.4241 84.3152 18.116 84.3152 17.0042C84.3806 13.6686 84.3152 10.333 84.3152 6.99741C84.3152 6.60499 84.2498 6.21257 84.1844 5.88555C84.0536 5.0353 83.5304 4.57748 82.6801 4.44667C82.6801 4.18505 82.6801 3.98885 82.6801 3.66183C84.1844 3.66183 85.6887 3.66183 87.193 3.66183C87.3238 3.66183 87.4546 3.85804 87.52 3.98885C87.6508 4.44667 87.7162 4.90449 87.7816 5.42772C88.6319 4.38127 89.6783 3.66183 90.921 3.40021C92.8177 2.94239 94.649 2.94239 96.4149 3.85804C98.05 4.70828 98.9656 6.08176 99.031 7.97847C99.0964 10.5292 99.0964 13.0799 99.1618 15.6307C99.1618 16.3501 99.2272 17.0042 99.2926 17.7236C99.3581 18.7047 99.7505 19.4241 100.862 19.4895C100.862 19.7511 100.862 19.9473 100.862 20.2089C98.5078 20.2089 96.2187 20.2089 93.8641 20.2089C93.8641 20.0127 93.8641 19.7511 93.8641 19.4895C95.303 19.3587 95.3684 18.2468 95.4338 17.135C95.4992 14.715 95.4992 12.2297 95.5646 9.80977C95.5646 9.35194 95.4992 8.95952 95.4338 8.5017C95.1068 6.99741 94.2566 6.34338 92.6869 6.27797C89.9399 6.08176 88.2394 8.04387 88.2394 10.2676C88.174 12.7529 88.3048 15.3037 88.3048 17.789C88.3048 18.6393 88.6319 19.2279 89.5475 19.4895C89.5475 19.4895 89.6783 19.8819 89.8745 20.2743Z"
      fill="black"
    />
    <path
      d="M116.559 3.46564C116.69 3.46564 116.821 3.46564 116.952 3.46564C117.148 3.46564 117.344 3.46564 117.409 3.59645C117.475 3.66185 117.54 3.79266 117.54 3.92347C117.54 4.11968 117.409 4.25048 117.279 4.31589C117.409 4.38129 117.475 4.44669 117.475 4.5775C117.54 4.77371 117.54 4.96992 117.606 5.03532H117.279C117.279 4.96992 117.213 4.83912 117.148 4.6429C117.082 4.44669 117.017 4.38129 116.886 4.38129H116.821V5.03532H116.494V3.46564H116.559ZM116.886 4.11967H117.017C117.148 4.11967 117.279 4.05427 117.279 3.85806C117.279 3.72725 117.213 3.66185 117.017 3.66185C116.952 3.66185 116.886 3.66185 116.886 3.66185V4.11967Z"
      fill="black"
    />
    <path
      d="M117.017 3.1386C117.606 3.1386 118.129 3.59643 118.129 4.25047C118.129 4.8391 117.671 5.36233 117.017 5.36233C116.363 5.36233 115.905 4.9045 115.905 4.25047C115.905 3.59643 116.428 3.1386 117.017 3.1386ZM117.017 2.81158C116.232 2.81158 115.578 3.46562 115.578 4.25047C115.578 5.03531 116.232 5.68934 117.017 5.68934C117.802 5.68934 118.456 5.03531 118.456 4.25047C118.456 3.46562 117.802 2.81158 117.017 2.81158Z"
      fill="black"
    />
  </svg>
);
